.scrollhost::-webkit-scrollbar {
  display: none;
}

.scrollhost-container {
  position: relative;

  height: 100%;
}
.scrollhost {
  overflow: auto;
  height: 100%;
  padding-right: 30px !important;
  scrollbar-width: none;
  -ms-overflow-style: none;
  position: relative;
}

.scrollhost-container > .faq {
  flex-basis: 100% !important;
  height: 623px !important;
}

.scroll-bar {
  width: 1px;
  height: 100%;
  right: 0;
  top: 0px;
  position: absolute;
  border-radius: 7px;
  bottom: 0px;
  background-color: #BFBFBF;
}

.scroll-thumb {
  width: 9px;
  height: 230px !important;
  margin-left: 1px !important;
  position: absolute;
  border-radius: 30px;
  opacity: 1;
  top: 0;
  right: -4px;
  background-color:#F0F0F0;
}

@media only screen and (min-width: 2000px) {
  .scrollhost-container > .faq {
    width: 800px !important;
    height: 623px !important;
  }
}
@media only screen and (min-width: 3800px) {
  .scrollhost-container > .faq {
    width: 1550px !important;
    height: 623px !important;
  }
}
