

@font-face {
  font-family: 'Montserrat Regular';
  src: url('./Montserrat-Regular.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat Medium';
  src: url('./Montserrat-Medium.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat Bold';
  src: url('./Montserrat-Bold.ttf') format('truetype');
  font-display: swap;
}
@font-face {
  font-family: 'Montserrat SemiBold';
  src: url('./Montserrat-SemiBold.ttf') format('truetype');
  font-display: swap;
}


 * {
   box-sizing: border-box;
 }
 body {
    -webkit-font-smoothing: antialiased !important;
}

   .mainContainer {
     padding: 0px !important;
   }

 .darkModeText {
   color: '#FFFFFF' !important;
 }

 .visuallyHidden {
   position: absolute;
           width: 1px;
           height: 1px;
           margin: -1px;
           padding: 0;
           overflow: hidden;
           clip: rect(0, 0, 0, 0);
           border: 0;
 }



 .fullBleedImage {
   width: 100vw;
position: relative;
left: 50%;
right: 50%;
top: 69px !important;
margin-left: -50vw;
margin-right: -50vw;
 }

 a[href="/mobileSkills"] {
  text-decoration: none !important;
}
#navbar, #careersNavbar {
  position: absolute;
  right: calc(71.5%);
  top: 350px !important;

  width: 700px;
  -webkit-transform: rotate(90deg);
 -moz-transform: rotate(90deg);
 -o-transform: rotate(90deg);
 -ms-transform: rotate(90deg);
 transform: rotate(90deg);
}

.hiringArticleTileDescription {
  padding-top: 10px !important;
  padding-right: 10px !important;
}

.articleTileDescription {
  margin-top: 0px !important;
  padding: 20px !important;
}
.yellowTile {
  background: #FFDB7D;
border-radius: 4px;
width: 49px;
height: 49px;
}

.navTitle {
  font-family: 'Gotham Medium' !important;
  font-weight: 325 !important;
  font-size: 16px !important;
  line-height: 22px !important;
  color: white !important;
}
/***Career Ladder***/

.flowChartTile {
  cursor: pointer;
padding: 25px 20px !important;
border-radius: 4px;
border: 2px solid var(--dark-rebrand-main-stroke-3, #404040);
background: var(--dark-rebrand-main-3, #1D1D1D);

}

.juniorTab.active,
.seniorTab.active,
.managementTab.active {
  color: #8C75FC !important;
  font-family: 'Montserrat SemiBold' !important;
}



.flowChartTile.active {

border: 2px solid var(--light-rebrand-extra-1, #7855FA);
background: rgba(41, 5, 117, 0.25);
box-shadow: 0px 4px 20px 0px rgba(120, 85, 250, 0.40);
}
.focusable-chevron:focus-visible {
  border: unset !important;
}
.focusable-tile:focus-visible {
  outline: 2px solid rgba(120, 85, 250, 0.75) !important;

  border: 2px solid rgba(41, 5, 117, 0.25);
}

.focusable-tile.active:focus-visible {
  border: 2px solid var(--light-rebrand-extra-1, #7855FA);
  outline: 2px solid rgba(120, 85, 250, 0.75);
  outline-offset: 2px;
  box-shadow: unset !important;

}

.jobLinks:focus-visible:after {
  border: 2px solid #7855FA !important;
  border-radius: 10px !important;

}
.jobLinks {
  padding-right: 10px !important;
}
.jobLinks {
  text-decoration: none !important;
}
.articleLinkSmall {
  text-decoration: none !important;
}
.jobLinks > div > span {
  text-decoration: none !important;
}
.faqAccordion .accordion-header .accordion-toggle:focus-visible:after {
  border: 2px solid #7855FA !important;
  border-radius: 10px !important;
  background-color: unset !important;
}
.focusable-tile::after {
  all: unset !important;
}



.careerData{

    margin-bottom: 100px !important;
    padding-right: 10px !important;
  }

.careerData.active {
  animation: fadeIn 2s;
}

.faqAnswers {
    animation: faqAnimation 0.2s;
}
.growthAnswers {
    animation: textAnimationReverse 0.2s;
}

@keyframes fadeIn {
  0% { opacity: 0; }
  100% { opacity: 1; }
}
@keyframes faqAnimation {
  from {
    margin-top: -5%;
    opacity: 0
  }
  to {
    margin-top: 0%;
    opacity: 1;
  }
}
@keyframes textAnimationReverse {
  from {
    margin-top: -10%;
  }
  to {
    margin-top: 0%;
  }
}


.customerTestimonial {
  scrollbar-width: none; /* Hides the scrollbar in Firefox */
  -ms-overflow-style: none;  /* Hides scrollbar in Internet Explorer and Edge */
}

/* For WebKit-based browsers (Chrome, Safari) */
.customerTestimonial::-webkit-scrollbar {
  display: none; /* Hides the scrollbar in Chrome and Safari */
}








.flip-card-growth {

  background: rgba(19, 19, 19, 0.75);

  border: 2px solid  #2C2C2C !important;
  border-radius: 19px !important;
  visibility: hidden !important;

}
.rolesCard {

  background: #222222;

  border: 2px solid  #222222 !important;
  border-radius: 10px !important;
  visibility: hidden !important;

}



.basicCardText {
  color: #AC9BFD !important;
  outline: none;
  font-family: 'Montserrat Regular' !important;
  font-weight: 400 !important;
  font-size: 12px !important;
  line-height: 24px !important;

}
.basicCardTitle {
  color: #AC9BFD !important;
  outline: none;
  font-family: 'Montserrat Medium' !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 24px !important;
  text-decoration: none;
  white-space: nowrap !important;

}

.flowChartSeniorTile {
  background: #1D1D1D;
border-radius: 4px;
padding-left: 20px !important;
margin-right: 16px !important;
}
.mobileSkillCarousel {
  margin-left: 25px !important;
  margin-right: 25px !important;
}


.mobileGrowthAccordion > .accordion-body {
  margin-top: 0px !important;
  padding: 10px;
  background: #131313;
  padding: 20px 20px 20px 70px;

}




.mobileskillsTab > .ntnx-tab-item > .ntnx-flex-item > div {
  width: 120px !important;
  text-align: center;
}

.skillTile {
  border: 1px solid black;
}
.navigation, .nav{
  display: none !important;
}

.mobileNavigation {
  display: block;
  background: #201F1F !important;
  z-index: 9999 !important;
  margin-top: 0px !important;
  padding: 14px !important;
  height: 70px !important;
  position: fixed;
  top:0;
  right: 0;
  left: 0;
}

.mobileExpanded {
  position: fixed;
  display: block;
  margin-top: 0px !important;
  z-index: 9999;
  top:70px;
  bottom: 0;
  left: 0;
  right: 0;
  overflow: hidden;
  background: #131313;
  align-items: flex-end !important;
  padding-right: 50px !important;
  animation: slideIn 0.3s;
}

@keyframes slideIn {
  from { left: 200px;}
  to {left: 0px;}
}

.mobileCollapsed {
  display: none !important;
  animation: slideOut 0.3s;
}

.mobileNavLinks {
  padding-top: 70px !important;

}

.mobileNavLinks > a {
  font-family: 'Montserrat SemiBold' !important;
  font-weight: 600;
font-size: 18px;
line-height: 29px;
color: #AC9BFD;
text-decoration: none;
}
.closeBtn {
  position: absolute;
  top: 0;
  right: 0;
  padding-right: 20px !important;
  padding-top: 15px !important;
}

   .navCircle {
     background: linear-gradient(221.73deg, #F7C57A 13.97%, #E437D3 123.71%);
     width: 16px;
     height: 16px;
   }

  .sectionTitle {
    font-family: 'Montserrat Bold' !important;
    font-weight: 700 !important;
    font-size: 36px !important;
    line-height: 50px !important;
  }
  .darkSectionTitle {
    color: #FFF !important;
    font-family: 'Montserrat Bold' !important;
    font-weight: 700 !important;
    font-size: 36px !important;
    line-height: 50px !important;
  }

  .darkSectionSmallTitle {
    color: #FFF !important;

font-family: 'Montserrat Bold' !important;
font-size: 20px !important;
font-style: normal;
font-weight: 600 !important;
line-height: 60px !important;
  }

  .sectionDescription {
    font-family: 'Montserrat Regular' !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 26px !important;
  }
  .sectionDescriptionRegularLarge {
    font-family: 'Montserrat Regular' !important;
    font-weight: 600 !important;
    font-size: 20px !important;
    line-height: 30px !important;
  }
  .sectionDescriptionMediumLarge {
    font-family: 'Montserrat Medium' !important;
    font-weight: 500 !important;
    font-size: 16px !important;
    line-height: 23px !important;
  }
  .sectionDescriptionLarge {
    font-family: 'Montserrat SemiBold' !important;
    font-weight: 600 !important;
    font-size: 16px !important;
    line-height: 20px !important;
  }
  .darkSectionDescription {
    color: #FFF !important;
    font-family: 'Montserrat Regular' !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 26px !important;
  }

  .darkSectionDescriptionBold {
    color: '#AB95FF' !important;
    font-family: 'Montserrat Bold' !important;
    font-weight: 700 !important;
    font-size: 16px !important;
    line-height: 40px !important;
  }



  .darkSectionDescriptionMedium {
    font-family: 'Montserrat Medium' !important;
    color: #AC9BFD !important;
    font-weight: 500 !important;
    font-size: 14px !important;
    line-height: 26px !important;
  }
  .sectionDescriptionSmall {
    font-family: 'Montserrat Regular' !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 24px !important;
  }
  .sectionDescriptionSmallBold {
    font-family: 'Montserrat SemiBold' !important;
    font-weight: 600 !important;
    font-size: 12px !important;
    line-height: 24px !important;
  }

  .darkSectionArticleDescriptionSmall {
    font-family: 'Montserrat Regular' !important;
    color: #AAA !important;
    font-weight: 325 !important;
    font-size: 14px !important;
    line-height: 24px !important;
  }
  .darkSectionDescriptionSmallFooter,
  .darkSectionDescriptionSmallFooter > div > span {
    font-family: 'Montserrat SemiBold' !important;
    cursor: pointer;
    text-decoration: none;
    color: #9D9D9D !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px !important;
  }
  .footerSubLink > div > span {
    font-family: 'Montserrat Medium' !important;
    color: #9D9D9D !important;
    font-weight: 500 !important;
    font-size: 12px !important;
    line-height: 21px !important;
  }
  .sectionDescriptionBold {
    font-family: 'Montserrat SemiBold' !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .darkSectionDescriptionBoldLarge {
    color: '#FFFFFF' !important;
    font-family: 'Montserrat Medium' !important;
    font-weight: 500 !important;
    font-size: 24px !important;
    line-height: 38px !important;
  }
  .sectionDescriptionBoldLarge {
    font-family: 'Montserrat SemiBold' !important;
    font-weight: 600 !important;
    font-size: 18px !important;
    line-height: 24px !important;
  }

  #introSection, #aboutSection, #peopleSection  {
     padding: 0px 16px 70px;
  }
  #partnersSection {
        padding: 70px 16px;
  }
  #articleSection, #rolesSection, #growthSection {
    padding: 70px 16px;
    background: #131313;
  }

  .articleCarouselDots {
    position: relative !important;
    gap: 5px;
    color: #B8BFCA;
    margin-left: 12px !important;
    justify-content: flex-start !important;
  }

  .articleCarouselDots > li > button,
    .teamDots > li > button {
    width: 30px;
height: 6px;
border-radius: 4px;
background: #715AF6;
border-color: #715AF6;
  }

  .articleCarouselDots > li > button:hover:active {
    background: #715AF6;
    border-color: #715AF6;
  }
  .teamDots > li > button,
  .articleCarouselDots > li > button {
background: #D9D9D9 !important;
background-color: #D9D9D9 !important;
color: #D9D9D9 !important;
border-color: #D9D9D9 !important;
  }
  .teamDots > li > button:hover{
    background: #D9D9D9 !important;
    background-color: #D9D9D9 !important;
    color: #D9D9D9 !important;
    border-color: #715AF6 !important;
  }
  .teamDots > li > button:active,
    .articleCarouselDots > li > button:active {
    background: #715AF6 !important;
    background-color: #715AF6 !important;
    color: #715AF6 !important;
    border-color: #715AF6 !important;
  }

  .teamDots > .react-multi-carousel-dot--active > button,
  .articleCarouselDots > .react-multi-carousel-dot--active > button{
    background: #715AF6 !important;
    color: #715AF6 !important;
    border-color: #715AF6 !important;
  }
  .mobileFooter {
    padding: 57px 16px 27px !important;
    background: #212121;
  }

  .articleTitle {
    font-family: 'Montserrat Medium' !important;
    font-weight: 350 !important;
    font-size: 14px !important;
    line-height: 17px !important;
  }




  /***Thoughts Carousel***/
  .thoughtsTile {
    width: 327px;
    height: 280px;
    padding-right: 20px !important;
    padding-top: 20px !important;
    padding-left: 20px !important;
    background: #FFFFFF;box-shadow: 0px 0px 12px rgba(99, 97, 97, 0.25);
border-radius: 8px;
  }
  .skillsTile {

background: #FFFFFF;
box-shadow: 0px 1px 12px rgba(187, 187, 187, 0.25);
border-radius: 8px;
flex: 1;
  }

   .mobileThoughtsTile {



background: #FFFFFF;
border-radius: 8px;
    }



  .profilePic {
    width: 71px;
  height: 71px;
  background: #D8ECFA;
  border-radius: 10px;
  }

  .profileName {
    font-family: 'Montserrat SemiBold' !important;
    font-style: normal;
  font-weight: 600 !important;
  font-size: 14px !important;
  line-height: 21px !important;
  color: var(--Iris-0, #7855FA) !important;
  }

  .tile {
    height: 172px;
    background: #D8ECFA;
    border-radius: 2px;
  }




  .careerdot {
    width: 10px;
  height: 10px;
  background: #715AF6;
  border-radius: 50%;
  }

  .careerdot:hover{
    background-image: radial-gradient(blue 30%, #B9BFF2 10%);
  }
  .sideQuest {
    border: 1px solid #D2D2D2;
border-radius: 4px;
padding: 15px !important;
flex: 1;
  }

  .growthCard {
    border: 1px solid #2C2C2C;
border-radius: 10px;
padding: 30px !important;
flex: 1;
background-color: #161616 !important;
  }

  .responsibilitiesDot {
    background: #D9D9D9;
   border-radius: 4px;
   width: 20px;
   height: 20px;
  }

  .linkButton {
    padding: 20px 35px 20px 20px!important;
    background-color: #7855FA !important;
  }
  .artefactsButton {
    padding: 0px 10px !important;
    border-radius: 10px !important;
   border: 1px solid var(--Iris-Purple, #7855FA) !important;
   background: #1C1435 !important;
   cursor: default !important;
   text-decoration: none;
   font-family: "Montserrat Regular" !important;
   color: #BCAEFF !important;
   font-weight: 400 !important;
   font-size: 12px !important;
   line-height: 26px !important;
  }
  .linkButton:hover
  {

    cursor: pointer !important;
  }



  .linkButton > a {
    display: flex;
    gap: 7px;
    justify-content: center;
    text-decoration: none;
    font-family: "Montserrat SemiBold" !important;
    color: #FFFFFF !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px !important;
  }




  .articleTile {
    border-radius: 10px;
    border: 1px solid rgba(255, 255, 255, 0.5);
    background: #131313;
    background-clip: border-box;



  }



  .nutanixBadge > .badge-count {
    border-color: #7855FA !important;
    font-size: 12px !important;
    color: #7855FA !important;
    background: #1A1A1A !important;
    border-radius: 35px !important;
  }

  .linkSmall {
    color: #7855FA !important;

  font-family: "Montserrat SemiBold" !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 21px !important;
  transition-property: color;
  transition-timing-function: ease;
  transition-duration: 1.17s;
  text-decoration: none;
  }
  .articleLinkSmall {
    color: #AC9BFD !important;

  font-family: "Montserrat SemiBold" !important;
  font-size: 12px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 21px !important;
  transition-property: color;
  transition-timing-function: ease;
  transition-duration: 1.17s;
  text-decoration: none;
  }



  .linkSmall:hover {
    cursor: pointer !important;
    text-decoration: none !important;
  }
  .brandColorTextRegular {
    font-family: 'Montserrat SemiBold' !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px !important;
    color: #AC9BFD !important;
  }

  .mobileAccordion{
    border-radius: 5px;
border: 1px solid #2C2C2C;
background: #1E1E1E;
padding: 21px 120px 22px 18px !important;
  }
  .mobileGrowthAccordion{
    border-radius: 5px;
border: 1px solid #2C2C2C;
background: #1E1E1E;
position: relative;

  }

  .mobileGrowthAccordion > .accordion-header {
    padding: 20px;
  }

  .mobileGrowthAccordion > .accordion-header > .accordion-toggle {
    width: 100% !important;

  }
.mobileGrowthAccordion:hover{
  cursor: pointer;
}

.mobileGrowthAccordion > .accordion-header > .accordion-toggle > .ntnx-flex-item > a{
  position: absolute;
  right: 20px !important;
}
  .partnersCarousel{
    background: #F6F6F6;
    padding: 50px 20px 50px 0px !important;
    width: 100vw !important;
  position: relative !important;
  left: 49% !important;
  right: 49% !important;
  margin-left: -50vw !important;
  margin-right: -50vw !important;


  }



.thoughItems {
  width: 420px !important;
}
  .mobileTeamCarousel {
    background: #000;
    padding: 50px 20px !important;
    width: 100vw !important;
  position: relative !important;
  left: 49% !important;
  right: 49% !important;
  margin-left: -50vw !important;
  margin-right: -50vw !important;
  }
  .mobilePeopleCarousel {
    background: #F6F6F6;
    padding: 50px 20px 70px !important;
    width: 100vw !important;
  position: relative !important;
  left: 49% !important;
  right: 49% !important;
  margin-left: -50vw !important;
  margin-right: -50vw !important;

  top: 69px !important;
  }

  .thoughtsCarousel{
    background: #F6F6F6;
    padding: 100px 0px 100px 200px !important;

  position: relative !important;


  width: 100vw !important;
left: 49% !important;
right: 49% !important;
margin-left: -50vw !important;
margin-right: -50vw !important;

  }

  .teamCarousel > .react-multiple-carousel__arrow:disabled,
  /* .peopleCarousel > .react-multiple-carousel__arrow:disabled, */
  .partnersCarousel > .react-multiple-carousel__arrow:disabled,
  .thoughtsCarousel > .react-multiple-
  :disabled {
    background: #FFFFFF !important;
  }
  .teamCarousel > .react-multiple-carousel__arrow:disabled::before,
  /* .peopleCarousel > .react-multiple-carousel__arrow:disabled::before, */
  .partnersCarousel > .react-multiple-carousel__arrow:disabled::before,
  .thoughtsCarousel > .react-multiple-carousel__arrow:disabled::before{
    color: #212121 !important;
  }

  .partnersCarousel > .react-multiple-carousel__arrow:focus-visible {
    outline: 2px solid rgba(120, 85, 250, 0.75);
    outline-offset: 2px !important;
  }


  .teamCarousel > .react-multiple-carousel__arrow,
  /* .peopleCarousel > .react-multiple-carousel__arrow, */
  .partnersCarousel > .react-multiple-carousel__arrow,
  .thoughtsCarousel > .react-multiple-carousel__arrow  {
    background: #212121 !important;
    min-width: 36px !important;
    min-height: 36px !important;
  }

  .partnersCarousel > .react-multiple-carousel__arrow--left {
    left: calc(8%);
  }

  .thoughtsCarousel > .react-multiple-carousel__arrow--left {
    left: calc(8%);
  }




 .partnerTile {

    width: 298px;
    height: 360px;
    padding: 45px 25px !important;

    background: #FFFFFF !important;

    border-radius: 10px;
    position: relative;
    left: 50%;
    transform: translateX(-50%);
  }

  .customerTestimonial {
    padding-bottom: 15px !important;
    overflow-y: auto !important;
    -webkit-mask-image: linear-gradient(to bottom, black 80%, transparent 100%) !important;
    mask-image: linear-gradient(to bottom, black 80%, transparent 100%) !important;
  }

  .brandColorText {
    font-family: 'Montserrat SemiBold' !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    color: #715AF6 !important;
  }

  .lightbrandColorText {
    font-family: 'Montserrat SemiBold' !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 20px !important;
    color: #C6BEFE !important;
  }

  .linkExtraSmall {
    color: #7855FA !important;
    text-decoration: none;

  font-family: "Montserrat SemiBold" !important;
  font-size: 14px !important;
  font-style: normal;
  font-weight: 600 !important;
  line-height: 21px !important;
  white-space: nowrap !important;
  }



  .skillsTab{
    justify-content: space-between !important;
    position: fixed !important;
    background: #232323 !important;
    padding: 8px 130px!important;
    width: 95vw !important;
    z-index: 9999;

  }

  .mobileskillsTab  {
    justify-content: space-between !important;
    position: fixed !important;
    background: #232323 !important;
    padding: 8px 130px!important;
    width: 100vw !important;
    z-index: 9999;
  }

  .mobileskillsTab {
    padding: 8px 16px !important;
    margin-top: 10px !important;
  }

  .mobileskillsTab::-webkit-scrollbar {
  display: none !important;
  }

  .skillsTab::before, .mobileskillsTab::before {
    background-color: #232323 !important;
  }

  .skillsTab > button > div > div > div ,
  .mobileskillsTab > button > div > div > div {
  color: #BCBCBD !important;

  font-family: 'Montserrat Medium' !important;
  font-size: 16px !important;
  font-style: normal;
  font-weight: 400 !important;
  line-height: normal;
  white-space: nowrap;
  letter-spacing: 0.16px !important;
  }



  #backLink {
    position: fixed;
    z-index: 10000;
    top: 0px;
    left: 0px;
    padding-left: 16px;
    padding-bottom: 10px;
    padding-top: 10px;
    color: #AC9BFD !important;
    background: #000 !important;
    width: 100vw !important;
  }

  .backLinkSeparator {
    position: fixed;
    z-index: 10000;
    top: 7px;
    left: 340px;
    width: 2px !important;
    background-color: #646464 !important;
  }

  #backLink > div > span {
    font-family: 'Montserrat Medium' !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    letter-spacing: 0.16px !important;

  }

  .skillsTab > .ntnx.ntnx-tab-item[data-type=tabs][data-appearance=active]:before, .ntnx.ntnx-tab-item[data-type=tabs][data-appearance=active]:hover:before,
  .skillsTab > .ntnx.ntnx-tab-item[data-type=tabs][data-appearance=normal]:active:before, .ntnx.ntnx-tab-item[data-type=tabs][data-appearance=normal]:hover:before,
   .mobileskillsTab > .ntnx.ntnx-tab-item[data-type=tabs][data-appearance=active]:before, .ntnx.ntnx-tab-item[data-type=tabs][data-appearance=active]:hover:before,
    .mobileskillsTab > .ntnx.ntnx-tab-item[data-type=tabs][data-appearance=normal]:active:before, .ntnx.ntnx-tab-item[data-type=tabs][data-appearance=normal]:hover:before{
    background-color: #715AF6 !important;
    height: 3px !important;
    bottom: -6px !important;
  }

 .skillsTab > .ntnx.ntnx-tab-item[data-type=tabs][data-appearance=active]  > div > div > div {
   color: #FFFFFF !important;
 }



  .skillsList {
    padding-left: 20px !important;
  }
  .skillsList>li:before{
    background: #AAA !important;
    margin-top: 11px !important;
  }

  .mobileskillsTab>li {
    align-items: center;
  }


  .mobileskillsTab>li:before{
    background: #FFFFFF !important;
    bottom: -6px !important;
  }

  .skillsJoinUs {
   background: #151516;
    padding: 84px 16px !important;
  }

  .customerCompanyBadge > .badge-count {
    background-color: rgba(174, 177, 255, 0.32) !important;
    color: #7855FA !important;
    line-height: 8px !important;
  }


   .rolesArrow{
     position: relative;
     display: inline-block;
     padding-bottom: 18px !important;
     align-self: center;

   }
   .buttonArrow, .articleArrow, .openingArrow, .articleCardArrow{
     position: relative;
     display: inline-block;
     padding: 8px 20px;
   }


   .rolesArrow::before {
     content: "";
  display: block;
  width: 18.5px;
  height: 1px;
  background-color: #AC9BFD;
  top: 50%;
  position: absolute;
  transition: width 0.5s ease-out;
   }
   .articleCardArrow::before {
     content: "";
  display: block;
  width: 18.5px;
  height: 1px;
  background-color: #AC9BFD;
  top: 50%;
  position: absolute;
  transition: width 0.5s ease-out;
   }
   .buttonArrow::before {
     content: "";
  display: block;
  width: 10px;
  height: 1.5px;
  background-color: #FFFFFF;
  top: 51%;
  position: absolute;
  transition: width 0.2s ease-out;
   }
   .articleArrow::before {
     content: "";
  display: block;
  width: 10px;
  height: 1.5px;
  background-color: #AC9BFD;
  top: 51%;
  position: absolute;
  transition: width 0.2s ease-out;
   }
   .openingArrow::before {
     content: "";
  display: block;
  width: 10px;
  height: 1.5px;
  background-color: #7855FA;
  top: 51%;
  position: absolute;
  transition: width 0.2s ease-out;
   }
   .rolesArrow::after {
     content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-top: 1px solid #AC9BFD;
      border-right: 1px solid #AC9BFD;
      transform: rotate(45deg);
      top: calc(50% - 5px);
      position: absolute;
      right: -19px;
      transition: right 0.5s ease-out;
   }
   .articleCardArrow::after {
     content: "";
      display: block;
      width: 10px;
      height: 10px;
      border-top: 1px solid #AC9BFD;
      border-right: 1px solid #AC9BFD;
      transform: rotate(45deg);
      top: calc(50% - 5px);
      position: absolute;
      right: -19px;
      transition: right 0.5s ease-out;
   }
   .buttonArrow::after {
     content: "";
      display: block;
      width: 8px;
      height: 7px;
      border-top: 1.5px solid #FFFFFF;
      border-right: 1.5px solid #FFFFFF;
      transform: rotate(45deg);
      top: calc(60% - 3px);
      position: absolute;
      right: -11px;
      transition: right 0.2s ease-out;
   }
   .articleArrow::after {
     content: "";
      display: block;
      width: 8px;
      height: 7px;
      border-top: 1.5px solid #AC9BFD;
      border-right: 1.5px solid #AC9BFD;
      transform: rotate(45deg);
      top: calc(60% - 3px);
      position: absolute;
      right: -11px;
      transition: right 0.2s ease-out;
   }
   .openingArrow::after {
     content: "";
      display: block;
      width: 8px;
      height: 7px;
      border-top: 1.5px solid #7855FA;
      border-right: 1.5px solid #7855FA;
      transform: rotate(45deg);
      top: calc(60% - 3px);
      position: absolute;
      right: -11px;
      transition: right 0.2s ease-out;
   }

     .careersArrow {
       position: relative;
       display: inline-block;
       padding: 8px;
     }

     .careersArrow::before {
       content: "";
    display: block;
    width: 10px;
    height: 1.5px;
    background-color: #7855FA;
    top: 51%;
    position: absolute;
    transition: width 0.2s ease-out;
     }
     .careersArrow::after {
       content: "";
        display: block;
        width: 8px;
        height: 8px;
        border-top: 1.5px solid #7855FA;
        border-right: 1.5px solid #7855FA;
        transform: rotate(45deg);
        top: calc(40% - 5px) !important;
        position: absolute;
        right: -11px;
        transition: right 0.2s ease-out;
     }
   .rolesCard:hover .rolesArrow::before {
     width: 25px;
     background-color: #FFFFFF;
   }
   .hiringArticleCard:hover .articleCardArrow::before {
     width: 25px;
     background-color: #FFFFFF;
   }
   .rolesCardHover .rolesArrow::before {
      width: 25px;
      background-color: #FFFFFF;
   }
   .linkButton:hover .buttonArrow::before {
     width: 18px;
   }
   .articleLink:hover .articleArrow::before {
     width: 18px;
   }
   .jobLinks:hover .openingArrow::before {
     width: 18px;
   }
   .rolesCard:hover .rolesArrow::after {
     right: -25px;
     border-top: 1px solid #FFFFFF;
     border-right: 1px solid #FFFFFF;
   }
   .hiringArticleCard:hover .articleCardArrow::after {
     right: -25px;
     border-top: 1px solid #FFFFFF;
     border-right: 1px solid #FFFFFF;
   }
   .rolesCardHover .rolesArrow::after {
     right: -25px;
     border-top: 1px solid #FFFFFF;
     border-right: 1px solid #FFFFFF;
   }
   .linkButton:hover .buttonArrow::after {
     right: -18px;
   }
   .articleLink:hover .articleArrow::after {
     right: -18px;
   }
   .jobLinks:hover .openingArrow::after {
     right: -18px;
   }


   .footerLink > a {
     text-decoration: none;
   }

   .skip-link {
     display: none !important;
   }

@media only screen and (min-width: 768px) {

  .darkSectionDescriptionMedium {
    font-size: 18px !important;
  }
  .linkButton > span {
    display: flex;
    gap: 7px;
    justify-content: center;
    text-decoration: none;
    font-family: "Montserrat SemiBold" !important;
    color: #FFFFFF !important;
    font-weight: 600 !important;
    font-size: 14px !important;
    line-height: 21px !important;
  }

  .skillsTab > div > div :focus-visible:after {
    border: 2px solid rgba(120, 85, 250, 0.75); /* Example border */
    border-radius: 10px;
  }
  .footerLink > a{
    width: fit-content;
  }

  .footerLink > a:focus-visible {
    outline: 2px solid rgba(120, 85, 250, 0.75); /* Example border */
    outline-offset: 2px;
    border-radius: 10px;
  }

  .articleArrowLink {
    align-self: flex-start !important;
    padding-right: 20px !important;
    padding-bottom: 10px !important;
  }
  .articleArrowLink:focus-visible:after {
    border: 2px solid rgba(120, 85, 250, 0.75) !important;
    border-radius: 10px !important;
  }
  .footerLink > a:focus-visible:after {
    all: unset !important;
  }
  .thoughtLink > div:focus-visible:after {
    border: 2px solid rgba(120, 85, 250, 0.75) !important;
    border-radius: 10px !important;
  }

  .redirectButton {
    max-width: fit-content !important;
  }

  .skip-link {
      display: block !important;
      position: absolute;
      top: -100px;
      left: 10px;
      background: #FFFFFF;
      color: #2557A7;
      padding: 15px;
      z-index: 99999;
      font-size: 14px !important;
      font-weight: 500 !important;
      font-family: "Montserrat Medium" !important;
  }

  .skip-link:focus {
      top: 10px;
  }

  .thoughtsContainer > div {
    justify-content: space-between;
    padding: 100px 150px !important;
    width: 100% !important;
    background: #F6F6F6;
  }
  .rolesModal > div {
    width: 95vw !important;
  }

  .aboutMainDiv {
    background: #F9F9F9;
    padding: 200px 0px 200px 200px !important;
    position: relative !important;
    margin-top: -50px !important;
    margin-left: -600px !important;
    flex: 1 1 auto;
  }

  .ntnx.ntnx-modal[data-appearance=overlay]:before {
    background: rgba(150, 150, 150, 0.70) !important;

  }

  .thoughLink:focus-visible:after {
    border: 2px solid rgba(120, 85, 250, 0.75) !important;
    border-radius: 10px !important;
  }

  .rolesModal > div > .modal-body::-webkit-scrollbar{
    display: none;
  }

  .rolesModal > div > .modal-body > div {
    padding: 0 !important;
  }
  .rolesModal > div > .modal-body {
    max-height: 85vh !important;
  }
  .rolesModal > div > .modal-footer {
    display: none !important;
  }

  .rolesModal > div > .ntnx-modal-header {
    background-color: #232323 !important;
    box-shadow: inset 0 -1px 0 0 #151516;
  }


  .rolesModal > div > div > div > .header-title {
    font-family: 'Montserrat Medium' !important;
    font-size: 16px !important;
    font-style: normal;
    font-weight: 400 !important;
    line-height: normal;
    letter-spacing: 0.16px !important;
    color: #AC9BFD !important;
  }

  .rolesModal > div > div > .actions-container > button > svg {
    color : #BCBCBD !important;
  }
  .rolesModal > div > div > .actions-container > button:focus-visible:after {
    border: 2px solid rgba(120, 85, 250, 0.75) !important;
    border-radius: 10px !important;
  }
  .rolesModal > div > div > .actions-container {
    cursor: pointer !important;
  }
  .rolesModal > div > div > .actions-container > button > .ntnx-icon:hover {
    color : #FFFFFF !important;
    cursor: pointer !important;
  }

  .peopleItems {
    width: 600px !important;
  }
  .blur {
    filter: blur(4px);
  }

  .navLine {
    margin-left: -30px;
    margin-right: 100px;
    transform: rotate(90deg);
    width: 1.5px;
    height: 400px;
  }

  .peopleDescription {
    flex-basis: 40%;
  }

  .reducedSection {
    width: 70%;
  }
  .rolesCard:hover {
    border-color: #7855FA !important;
    cursor: pointer;
  }
  .rolesCardHover::after{
     all: unset !important;
  }
  .rolesCardHover {
    border-color: #7855FA !important;
    border-radius: 10px !important;
  }

  .growthCard:hover {

border: 1px solid #7855FA;
background-color: #131313 !important;
box-shadow: 0px 4px 20px 0px #1C1435;
  }
  .growthCardHover {

border: 1px solid #7855FA;
background-color: #121212 !important;
box-shadow: 0px 4px 20px 0px #1C1435;
  }

  .growthCard:hover .growthCardDesc {
    color: #AC9BFD !important;
  }
  .growthCardHover .growthCardDesc {
    color: #AC9BFD !important;
  }
  .growthCard:hover .growthCardTitle {
    color: #FFFFFF !important;
  }
  .growthCardHover .growthCardTitle {
    color: #FFFFFF !important;
  }
  .growthCard:hover .growthImage,
  .footerLink:hover .footerImage {
    transition: filter .3s ease-in-out;
    filter: brightness(2);
  }

  .growthCardHover .growthImage {
    transition: filter .3s ease-in-out;
    filter: brightness(2);
  }
  .rolesCard:hover .basicCardText {
    color: #FFFFFF !important;
  }
  .rolesCardHover .basicCardText {
    color: #FFFFFF !important;
  }

  .teamItems {
    width: 580px !important;

  }
  .teamCarousel {
    width: 580px;

  }

  .peopleCarousel {
    width: 700px !important;
  }

.owl-stage { margin-left: -10%}
  .owl-carousel .owl-dots {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    width: 100%;
    text-align: center;

  }

  .owl-stage-outer {
    height: 400px !important;
  }
  .owl-carousel .owl-dots .owl-dot{
    display: inline-block;

  }
  .owl-carousel .owl-dots .owl-dot span{
    /* background: #eaa400; */
    background: #56423D;
    display: inline-block;
    width: 30px;
height: 6px;
border-radius: 4px;
background: #D9D9D9;
border-color: #D9D9D9;
  }

  .owl-dot.active > span {
    background: #715AF6 !important;
    border-color: #715AF6 !important;
  }
  .owl-dot:hover > span {
     border-color: #715AF6 !important;
  }

  .owl-dot:focus-visible {

    outline: 2px solid rgba(120, 85, 250, 0.75); /* Example border */
    border-radius: 4px;

  }
  .tabButton:focus-visible:before {

    content: '';
    position: absolute;
    top: -2px; /* Adjust this value to control the gap from the top */
    left: -6px; /* Adjust this value to control the gap from the left */
    right: -6px; /* Adjust this value to control the gap from the right */
    bottom: -2px; /* Adjust this value to control the gap from the bottom */
    border: 2px solid rgba(120, 85, 250, 0.75); /* Example border */
    border-radius: 10px; /* Example border radius */

  }
  .tabButton:focus-visible:after{
    all: unset !important;
  }





  .peopleItem {
    width: 500px !important;
    height: 500px !important;
  }

  .owl-item:not(.active) {
    opacity: 0.4 !important;
    filter: blur(5px) !important;
  }


  .teamCarousel > ul {
    row-gap: 150px;
  }

  .skillsPage {
    position: relative;
  }

  .sideQuest:hover {
    border-radius: 4px;
border: 1px solid var(--dark-rebrand-highlight-stroke-1, #9D83FF);
background: #FFF;
box-shadow: 0px 4px 20px 0px rgba(120, 85, 250, 0.05);
  }

  .sideQuest:focus-visible{
    border-radius: 4px !important;
border: 1px solid var(--dark-rebrand-highlight-stroke-1, #9D83FF) !important;
  }

  .linkSmall {
    font-size: 16px !important;
  }


  .growthCardDesc {
    color: #646464 !important;
    font-family: 'Montserrat Regular' !important;
    font-size: 16px !important;
font-style: normal !important;
font-weight: 400 !important;
line-height: 24px !important;
  }
  .basicCardText {
    font-family: 'Montserrat Medium' !important;
    font-size: 18px !important;

    margin-top: 0px !important;
  }

  .growthCardTitle {
    font-family: 'Montserrat Medium' !important;
    font-size: 18px !important;
      color: #AC9BFD !important;
      font-weight: 500 !important;
line-height: 24px !important;
  }


  .teamDots {
    gap: 10px !important;
  }




  .footerLink:hover > div > a,
  .footerLink:hover > span,
  .footerLink:hover > a > div > span > div > div,
  .footerLink:hover > a > div > div,
  .footerLink:hover > div > a > div > span {
    color: #FFFFFF !important;
    cursor: pointer;
   }

   .footerLink > a > div > span {
     text-decoration: none !important;
   }


  .linkButton:hover {
    border-radius: 4px;
background: var(--iris-purple-700, #612BD2) !important;
box-shadow: 4px 4px 20px 0px rgba(120, 85, 250, 0.25) !important;
  }
  .linkButton:focus-visible:before {

    content: '';
    position: absolute;
    top: -6px; /* Adjust this value to control the gap from the top */
    left: -6px; /* Adjust this value to control the gap from the left */
    right: -6px; /* Adjust this value to control the gap from the right */
    bottom: -6px; /* Adjust this value to control the gap from the bottom */
    border: 2px solid rgba(120, 85, 250, 0.75); /* Example border */
    border-radius: 6px; /* Example border radius */

  }
  .linkButton:focus-visible:after{
    all: unset !important;
  }



  .sectionDescriptionSmall {
    font-family: 'Montserrat Regular' !important;
    font-weight: 400 !important;
    font-size: 14px !important;
    line-height: 26px !important;
  }
  .sectionDescriptionSmallBold {

    font-size: 14px !important;

  }
  .sectionDescriptionExtraSmall {
    font-family: 'Montserrat Regular' !important;
    font-weight: 400 !important;
    font-size: 12px !important;
    line-height: 21px !important;
  }
  .articleLink {
    color:  #AC9BFD !important;
text-align: center;
font-family: 'Montserrat SemiBold' !important;
font-size: 14px !important;
font-style: normal;
font-weight: 600 !important;
line-height: 21px !important;
gap: 5px !important;

  }

  .articleParentLink {
    text-decoration: none !important;
    width: fit-content !important;

  }
  .articleParentLink:focus-visible:after {
    border: 2px solid rgba(120, 85, 250, 0.75) !important;
    border-radius: 10px !important;
  }
  .articleLink {
    padding-right: 16px !important;
    padding-left: 4px !important;
    padding-top: 6px !important;
    padding-bottom: 6px !important;
  }

  .articleParentLink > div > span {
    text-decoration: none !important;
  }



  .skillsJoinUs {

    padding: 50px 130px !important;
  }


  .partnerItems {
    width: 350px !important;
  }



  .partnerTile{
    width: 298px;
    height: 350px;
    padding: 40px !important;
    background: #FFFFFF !important;

  border-radius: 10px;
  }

  .partnersCarousel {
    padding: 63px 212px !important;
  }

  .partnersCarousel > .react-multi-carousel-track {
    visibility: hidden;
  }

  .articleTileDescription {

    padding: 30px !important;
  }

  .articleTile {
background: #1D1D1D !important;
border: none !important;
visibility: hidden !important;
}


.articleTile:hover .taleArticleTitle {
  color: #FFFFFF !important;
}

.articleTile:hover .articleImage {
  filter: grayscale(0%);
}

.articleImage  {
  max-width: 100%;
  filter: grayscale(100%);
  border-top-left-radius: 10px; /* Adjust the radius as needed */
  border-top-right-radius: 10px; /* Adjust the radius as needed */

}




.animatedCard{
  animation-name: animatedCard;
  animation-duration: 0.8s;
  position: relative;
  animation-timing-function: ease;
  visibility: visible !important;
}


  .animatedTileFirst {
    animation-name: animatedTile;
    animation-duration: 0.5s;
    position: relative;
    animation-timing-function: ease;
    visibility: visible !important;
  }
  .animatedTileSecond {
    animation-name: animatedTileSecond;
    animation-timing-function: ease;

    animation-duration: 0.5s;
    position: relative;
    visibility: visible !important;
  }
  .animatedTileThird {
    animation-name: animatedTileThird;
    animation-timing-function: ease;

    animation-duration: 0.5s;
    position: relative;
    visibility: visible !important;
  }

  .animatedCarousel {
    animation-name: animatedCarousel;
    animation-timing-function: ease;

    animation-duration: 0.5s;
    position: relative;
    visibility: visible !important;
  }

    .animatedCarouselRightButton {
      animation-name: animatedCarouselRightButton;
      animation-timing-function: linear;

      animation-duration: 1s;
      position: relative;
      visibility: visible !important;
    }
    .animatedCarouselLeftButton {
      animation-name: animatedCarouselLeftButton;
      animation-timing-function: linear;

      animation-duration: 1s;
      position: relative;
      visibility: visible !important;
    }

  .animatedJoinUs {
    animation-name: animatedTileFirst;
    animation-timing-function: linear;

    animation-duration: 2s;
    position: relative;
    visibility: visible !important;
  }

  @keyframes animatedCarousel {
    0% {
      left: 500px;
    }
    100% {
      left: 0px;
    }

}
@keyframes animatedCarouselRightButton {
  0% {
    left: 500px;
  }
  100% {
    right: calc(80%);
  }

}
@keyframes animatedCarouselLeftButton {
  0% {
    right: calc(200%) !important;
  }
  100% {
    left: calc(12% + 1px) !important;
  }

}
@keyframes animatedCard {
 0% {
   top: 500px;
 }
 70% {
   top: 0px;
 }
 100% {
   top: 0px;
 }

}
  @keyframes animatedTile {
   0% {
     top: 500px;
   }
   70% {
     top: 0px;
   }
   100% {
     top: 0px;
   }

}

  @keyframes animatedTileSecond {
   0% {
     top: 500px;
   }
   10% {
     top: 500px;
   }

   100% {
     top: 0px;
   }

}

  @keyframes animatedTileThird {
    0% {
      top: 500px;
    }
    40% {
      top: 500px;
    }
    100% {
      top: 0px;
    }

}



  .footer {
    background: #212121 !important;
    padding: 50px 212px !important;
    position: relative;
    z-index: 5000;
  }


  .hiringArticleCard {
    background: #1D1D1D;

border-radius: 4px;
z-index: 5000;

  }


  .hiringArticleCard:hover .hiringArticleTitle {
    color: #FFFFFF !important;
  }



  .sideQuest {

padding: 30px !important;
  }


  .responsibilitiesDot {
    background: #D9D9D9;
   border-radius: 4px;
   width: 25px;
   height: 25px;
  }


  #peopleSection {
     padding: 60px 0px 0px 212px;
  }
  #peopleSection > section > div > div:first-child {
    padding: 140px 0px !important;
  }
  #introSection {
    background: #000 !important;
    padding: 175px 30px 132px 212px;
  }
  #aboutSection {
    padding: 50px 212px 0px;
  }

  #articleSection, #rolesSection, #growthSection {
     padding: 140px 212px 140px;
     background: #131313 !important;
     overflow-y: hidden !important;
  }










  .growthGrid {
    display: grid;
    grid-template-columns: auto auto auto;
    column-gap: 16px;
    row-gap: 16px;
    padding-top: 80px !important;


  }

  .growth-card {

    perspective: 1000px;
    cursor: pointer;
    height: 221px;
    width: 246px;
    box-shadow: 0px 0px 0px grey;
 -webkit-transition:  box-shadow .6s ease-out;
    box-shadow: .8px .9px 3px grey;
  }

  .growth-card:hover{
    box-shadow: 1px 8px 20px grey;
  -webkit-transition:  box-shadow .6s ease-in;
  }

  .growth-flip-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    text-align: center;
    transition: transform 0.6s;
    transform-style: preserve-3d;
    box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
  }

  .growth-card.is-flipped .growth-flip-card-inner {
    transform: rotateY(180deg);
  }

  .growth-flip-card-front, .growth-flip-card-back {
    position: absolute;
    width: 100%;
    height: 100%;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
  }



  .growth-flip-card-back {
    transform: rotateY(180deg);
    background: #F1F5F8;
    text-align: left;
  }

.item1 {
  grid-column: 1/2;
  height: 221px;
  width: 246px;
}

.item5{
  grid-column: 1/3;
  height: 130px;
  width: 500px;
}

.mobileNavigation{
  display: none !important;
}

.control-dots {
  position: relative !important;
  padding-bottom: 100px !important;
}
 #partnersSection{
  padding: 80px 212px 0px;
}
 #joinUsSection{
   padding: 140px 212px;
   overflow-y: hidden !important;
 }
 .joinUsImage {
   padding-top: 222px;
 }


.header--fixed {
  position: absolute;
  top: 0;
  width: 100%;
}


.nav{
  display: flex !important;
  width: 100%;
  padding: 12px !important;
  align-items: center;




}



.nav-item {
  padding: 20px 0px !important;
	display: inline;


  color: #8570FF;
  position: absolute !important;
  transform: rotate(180deg);
  top: 185px !important;


}
.firstItem {
  left: 100px !important
}
.secondItem {
  left: 210px !important;
}
.thirdItem {
  left: 290px !important;
}
.fourthItem {
  left: 390px !important;
}
.secondItemCareers {
  left: 210px !important;
}
.secondItemSystem {
  left: 225px !important;
}
.thirdItemCareers {
  left: 340px !important;
}
.fourthItemCareers {
  left: 440px !important;
}

.secondItemIntro {
  left: 190px !important;
}
.thirdItemIntro {
  left: 290px !important;
}
.fourthItemIntro {
  left: 390px !important;
}

.nav-item .active{
  font-family: 'Montserrat Bold' !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.nav-item>a {
  padding: 0px 10px 1px !important;
  background: unset !important;
  border: unset !important;
  cursor: pointer;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-family: "Montserrat Medium" !important;
}
.nav-item>a:focus-visible {
  outline: 2px solid rgba(120, 85, 250, 0.75);
  border-radius: 10px !important;
}
.nav-item>.active::before {
 transform: translateX(-50%);
 border-radius: 100%;
 position: absolute;
 background: #8570FF;
 bottom: 12px;
 height: 4px;
 content: '';
 width: 4px;
 left: 50%;
}
.nav-item > a:hover{
  cursor: pointer;
  font-family: 'Montserrat Bold' !important;
}


.header {
  display: flex;
  justify-content: space-around;
  width: 100%;
  padding: 20px 0;

  /* background-color: aqua; */

}
.headroom {
  position: fixed !important;
  top: 43px !important;
}

/***main navigation styling***/

.navigation{
  display: flex !important;
  align-items: center;
  background: #232323;
  position: fixed;
  z-index: 9999;
  top: 0;
  left: 0;
  width: 100%;
  padding-left: calc(4%) !important;
}

.navButton > a {
  color: #BCBCBD;
  font-family: "Montserrat Medium" !important;
font-size: 16px !important;
font-weight: 400 !important;
  text-decoration: none;

  white-space: nowrap;
}
.navButton {
  padding: 7px 10px 5px;
}

.navButton > a:focus-visible {
  outline: unset !important;
}

.navButton:has(a:focus-visible) {
  outline: 2px solid rgba(120, 85, 250, 0.75);
  border-radius: 10px;


}
.navButton >a:hover{
  color: #FFFFFF;

font-weight: 600 !important;
white-space: nowrap !important;
}
.navButton >a.active{
  color: #FFFFFF;
  font-family: "Montserrat Medium" !important;
font-size: 16px !important;
font-weight: 600 !important;
padding-bottom: 22px !important;
border-bottom: 3px solid #8D79FF;

}



.sectionTitle {
  font-weight: 700 !important;
  font-size: 48px !important;
  line-height: 60px !important;
}
.sectionTitleSmall {
  font-family: 'Montserrat Bold' !important;
  font-weight: 325 !important;
  font-size: 36px !important;
  line-height: 56px !important;
}
.darkSectionTitle {
  font-family: 'Montserrat Bold' !important;
  font-weight: 325 !important;
  font-size: 48px !important;
  line-height: 56px !important;
  color: #FFFFFF !important;
}
.darkSectionTitleSmall {
  font-family: 'Montserrat Bold' !important;
  font-weight: 325 !important;
  font-size: 36px !important;
  line-height: 56px !important;
  color: #FFFFFF !important;
}
.articleTitle {
  font-weight: 350 !important;
  font-size: 28px !important;
  line-height: 56px !important;
}




.tile {
  height: 172px;
  background: #D8ECFA;
  border-radius: 2px;
}




.sectionDescription {
  font-weight: 400 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}
.sectionDescriptionLarge {
  font-family: 'Montserrat Regular' !important;
  font-weight: 400 !important;
  font-size: 20px !important;
  line-height: 30px !important;
}
.sectionDescriptionMediumLarge {
  font-family: 'Montserrat Medium' !important;

  font-weight: 500 !important;
  font-size: 20px !important;
  line-height: 23px !important;
}
.sectionDescriptionArticleDesc {
  font-family: 'Montserrat Regular' !important;

  font-size: 14px !important;
font-weight: 400 !important;
line-height: 21px !important;
letter-spacing: -0.42px !important;
}
.darkSectionDescription {
  font-family: 'Montserrat Regular' !important;
  color: #FFFFFF !important;
  font-weight: 325 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}
.skillSectionDescription {
  font-family: 'Montserrat Regular' !important;
  color: #AAA !important;
  font-weight: 325 !important;
  font-size: 16px !important;
  line-height: 26px !important;
}

.darkSectionDescriptionSmall {
  font-family: 'Montserrat Regular' !important;
  color: #FFFFFF !important;
  font-weight: 325 !important;
  font-size: 14px !important;
  line-height: 24px !important;
}






.sectionDescriptionBold {
  font-weight: 600 !important;
  font-size: 16px !important;
  line-height: 21px !important;
}


.sectionDescriptionBoldLarger {
  font-family: 'Montserrat SemiBold' !important;
  font-weight: 600 !important;
  font-size: 20px !important;
  line-height: 24px !important;
  white-space: nowrap !important;
}

.sectionDescriptionBoldXtraLarge {
  font-family: 'Montserrat Medium' !important;
  font-weight: 350 !important;
  font-size: 24px !important;
  line-height: 26px !important;
}


.roles-tiles {
  display: grid !important;
  grid-template-columns: 200px 200px 200px;
  gap: 16px 16px;
}




/***Partners Carousel***/




/***Colors***/
body {
  background-color: lightslategrey;
}

.button {
  border: 1px solid #fff;
  opacity: .6;
  outline: none;
  background-color: #E7E7E7;
}

.button:hover,
.button:focus {
  opacity: 1;
  background-color: #2AB3EE;
  box-shadow: 0 0 0 3px lightBlue;
}

.selected {
  background-color: #2AB3EE;
  box-shadow: 0 0 0 3px lightBlue;
}
#foundationLottie {
  width: 617px;
  height: 586px;
}
}

@media only screen and (min-width: 1023px) and (max-width: 1366px){

  .partnersCarousel > .react-multiple-carousel__arrow--left {
    left: calc(3%);
   }
  .teamDots > li > button:active,
  .teamDots > li > button:hover {
    background: #715AF6 !important;
    background-color: #715AF6 !important;
    color: #715AF6 !important;
    border-color: #715AF6 !important;
  }
  .reducedSection {
    width: 100%;
  }
  #introSection, #aboutSection, #partnersSection, #rolesSection, #joinUsSection, #growthSection, #foundationSection, #articleSection, #principlesSection, #artefactsSection, #hireSection, .skills, .skillsPage{
    padding: 120px 48px !important;
  }
  #peopleSection {
    padding-left: 48px;
  }
  .skillsTab{
    padding: 0px 48px !important;
  }
  .footer {
    padding: 80px 48px 10px !important;
  }
  .partnersCarousel {
    margin-top: 100px !important;
    padding: 120px 48px !important;
  }
  .nav-item > .active::before {
   /* Remove the styles by overriding */
   content: none;
 }
}

@media only screen and (min-width: 1441px) and (max-width: 1512px) {
  .partnersCarousel > .react-multiple-carousel__arrow--right,
  .thoughtsCarousel > .react-multiple-carousel__arrow--right{
    right: calc(10%);
   }
   .thoughtsCarousel {
     width: 95vw !important;
   }
   #joinUsSection {
     padding: 200px 212px !important;
   }
}


@media only screen and (min-width: 1513px) {

  .reducedSection {
    width: 70%;
  }




  #peopleSection > div > div:first-child{
    width: 466px;
    margin-left: auto;
    flex-grow: 0.8;

  }
  .skillsPage {
    padding: 192px 212px;
  }


  #introSection {
    padding: 268px 30px 198px 212px;
  }
  #aboutSection {
    padding: 50px 212px 0px;
  }
  #articleSection {
    padding: 204px 212px;
  }
  #joinUsSection {
    padding: 268px 212px;
  }
  #rolesSection, #growthSection {
    padding: 192px 212px 226px;
  }




  .navigation {
    padding-left: calc(4.75%) !important;
  }


}

@media only screen and (min-width: 1512px) and (max-width: 1791px) {
  #navbar, #careersNavbar {
    right: 72.5%;
  }
  .thoughtsCarousel {
    width: 90vw !important;
  }
}

@media only screen and (min-width: 1792px) and (max-width: 1999px) {
  .navigation {
    padding-left: calc(4.75%) !important;
  }
  #navbar, #careersNavbar {
    right: 75.5%;
  }
  .aboutMainDiv {
    margin-left: -800px !important;
  }
}

@media only screen and (min-width: 1919px) {
  .aboutMainDiv {
    margin-left: -1000px !important;
  }
  /* Firefox-specific styles */
  @supports (-moz-appearance: none) {
    .navLine {
      margin-left: -80px;
      margin-right: 80px;
    }

    #navbar, #careersNavbar {
      top: 400px !important;
    }
  }
}

@media only screen and (min-width: 2000px) {

  .thoughtsContainer {
    background: #F6F6F6;
  }


  #introSection > section > div,
  #aboutSection > section > div,

  #partnersSection > section > div,
  #articleSection > section > div,
  #rolesSection > section > div,
  #growthSection > section > div,
  #joinUsSection > section > div,
  #foundationSection > section > div,
  #artefactsSection > section > div,
  #principlesSection > section > div,
  #hireSection > section > div,
  #careerGrowthSection > section > div,
  #faqSection > section > div,

  .internshipArticles > div,
  .footer > div,
  .skills > div,
  .skillsPage > div,
  .skillsJoinUs > div,
  .thoughtsContainer > div  {
    width: 1440px !important;
    margin: auto !important;
  }

  .navigation {
    padding-left: calc(14.5%) !important;
  }

  .mainContainer {
    max-width: 100%;

  }
  body {
    max-width: 100% !important;
  }

  .peopleDescription {
    flex-basis: 50%;
  }

  .partnersCarousel > .react-multi-carousel-track {
    gap: 200px;
  }

  .partnerTile {
    width: 500px;
  }
  .reducedSection {
    width: 50%;
  }




  #foundationLottie {
    width: 708px !important;
    height: 842px !important;
  }
  #peopleSection > section > div > div:first-child {
    width: 466px;
    padding: 210px 0px 555px 330px !important;
  }
  #peopleSection > section > div > div:last-child {
    flex-grow: 2.5;
  }
  .peopleCarousel {
    width: 750px !important;
  }

  #aboutSection {
    max-width: 100%;
    padding: 50px 132px 0px;
  }
  #introSection {
    max-width: 100%;
    padding: 382px 132px 312px;
  }
  #articleSection {
    max-width: 100%;;
    padding: 318px 132px;
  }
  #joinUsSection {
    max-width: 100%;
    padding: 382px 212px !important;
  }
  #rolesSection, #growthSection {
    padding: 280px 132px 384px;
  }
  #partnersSection{
   padding: 210px 132px 0px;
 }
  .partnersCarousel,
  .thoughtsCarousel {
    margin-top: 100px !important;
    padding: 140px 0px 140px 550px !important;
  }


  .joinUsImage {
    padding-top: 315px !important;
  }


}
@media only screen and (min-width: 3800px) {

  #introSection > section > div,
  #aboutSection > section > div,

  #partnersSection > section > div,
  #articleSection > section > div,
  #rolesSection > section > div,
  #growthSection > section > div,
  #joinUsSection > section > div,
  #foundationSection > section > div,
  #artefactsSection > section > div,
  #principlesSection > section > div,
  #hireSection > section > div,
  #careerGrowthSection > section > div,
  #faqSection > section > div,
  .thoughtsContainer > div,
  .internshipArticles > div,
  .footer > div,
  .skills > div,
  .skillsPage > div,
  .skillsJoinUs > div  {
    width: 2400px !important;
    margin: auto !important;
  }

  .thoughtsContainer {
    background: #F6F6F6;
  }

  .aboutMainDiv {

    margin-left: -1500px;

  }
  .teamImageSvg {
    width: 100%;
  }

  #navbar, #careersNavbar {
    right: calc(76.3%);
    top: 400px !important;
  }


  #peopleSection > section > div > div:first-child{
    max-width: 2000px;
    padding: 210px 200px 300px 550px !important;
    flex-grow: 2;

   }
   .partnersCarousel,
   .thoughtsCarousel {
     margin-top: 100px !important;
     padding: 140px 0px 140px 800px !important;
   }
   .partnersCarousel > .react-multiple-carousel__arrow--left,
   .thoughtsCarousel > .react-multiple-carousel__arrow--left{
     left: calc(16%);
    }

   .peopleDescription {

     flex-basis: 400% !important;
   }



}
