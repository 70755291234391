@import "../App.css";


  #foundationSection, #principlesSection, #artefactsSection {
    padding: 70px 16px;
  }
  #artefactsSection {
    background: #151516 !important;
  }
  #foundationSection {
    padding: 0px 16px 70px;
    background: #F6F6F6 !important;
  }

  .linkLarge > .ntnx-flex-item > span,
  .linkLarge{

color: #AC9BFD !important;
font-weight: 350 !important;
font-size: 16px !important;
line-height: 16px !important;
font-family: 'Montserrat SemiBold' !important;

  }

  .artefactParentLink {
    width: fit-content;
    padding-right: 10px !important;
  }

  .artefactParentLink:focus-visible:before {

    content: '';
    position: absolute;
    top: -6px; /* Adjust this value to control the gap from the top */
    left: -6px; /* Adjust this value to control the gap from the left */
    right: -6px; /* Adjust this value to control the gap from the right */
    bottom: -6px; /* Adjust this value to control the gap from the bottom */
    border: 2px solid rgba(120, 85, 250, 0.75); /* Example border */
    border-radius: 10px; /* Example border radius */

  }
  .artefactParentLink:focus-visible:after{
    all: unset !important;
  }


  .artefactLink > .ntnx-flex-item > span,
  .artefactLink {
    color: #AC9BFD !important;

  }
  .artefactParentLink > div > span {
    text-decoration: none !important;
  }

  .artefactParentLink:hover .articleArrow::before {
     width: 18px;
   }
   .artefactParentLink:hover .articleArrow::after {
     right: -18px;
   }

.artefactMobileCards{
  padding: 20px 24px !important;
}


.principleDetail {
  border: 1px solid #D6E1FF;
box-shadow: 0px 0px 6px rgba(197, 197, 197, 0.25);
border-radius: 4px;
padding: 15px !important;
}

.internshipArticles {
  width: 100vw !important;
position: relative !important;

left: 49% !important;
right: 49% !important;
margin-left: -50vw !important;
margin-right: -50vw !important;
  padding: 70px 16px !important;
background: #212121;
}

.mobileInternshipCards {
  border-radius: 4px;
border: 1px solid rgba(120, 85, 250, 0.25);
background: #222;
padding: 20px !important;
}



@media only screen and (min-width: 768px) {



  #principlesSection, #foundationSection{
     padding: 140px 212px;
  }

  #foundationSection {
    background: #F6F6F6;
  }

  #artefactsSection {
    background: #151516;
    padding: 140px 212px;
  }

  .principleCarousel {
    width: 500px;
  }

  .internshipArticles {

  padding: 120px 150px !important;

  }

  .principlesCard {
    border-radius: 4px;
border: 1px solid #D2D2D2;
padding: 20px 20px 68px !important;
flex: 1 1 0px;

  }

  .principlesCard:hover{

border: 1px solid var(--dark-rebrand-highlight-stroke-1, #9D83FF);

box-shadow: 0px 4px 20px 0px rgba(120, 85, 250, 0.25);
  }

  .principleImage {

background: linear-gradient(118.96deg, rgba(204, 31, 145, 0.3) -17.99%, rgba(228, 150, 32, 0.078) 84.08%), linear-gradient(243.49deg, #1383D5 -0.03%, rgba(158, 234, 152, 0) 101.5%);
border-radius: 4px;
width: 500px;
height: 290px;
  }



  .principleDetail {
    border: 1px solid #D6E1FF;
box-shadow: 0px 0px 6px rgba(197, 197, 197, 0.25);
border-radius: 4px;
padding: 25px !important;
  }

.dot {
  width: 14px;
height: 14px;
background: #656A94;
margin-top: 5px !important;
border-radius: 50%;
}

.dot.hoveredState{
   background-image: radial-gradient(blue 30%, #B9BFF2 10%);
}
.dot.active{
background-image: radial-gradient(blue 30%, #B9BFF2 10%);
}

}
@media only screen and (min-width: 2000px) {
  #foundationSection {
    padding: 250px 132px 180px !important;
  }
  #principlesSection {
    padding: 240px 132px 260px !important;
  }
  #artefactsSection {
    padding: 227px 132px 144px !important;
  }
}
