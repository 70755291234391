@import "../App.css";

 #hireSection, #careerGrowthSection, #faqSection {
   padding: 70px 16px !important;
}
#careerGrowthSection, #faqSection {
  background: #F9F9F9;
}
#openingsSection {
  padding: 0px 16px 70px !important;
}

.faqAccordion:hover{
  cursor: pointer;
}

.hiringCard {
  position: relative;
animation-name: example;
animation-duration: 5s;
animation-timing-function: linear;
animation-delay: 2s;
}


.hireTileDescription {
  padding-top: 20px !important;
  padding-left: 30px !important;
  padding-right: 25px !important;
  width: 100%;
  color: #AC9BFD !important;
}

@keyframes example {
  from { transform: translateY(100%); }
  to { transform: translateY(-100%); }
}


.hireTileTitle {
  color: #AC9BFD !important;
  padding-left: 30px !important;
  padding-top: 0px !important;
  padding-right: 10px !important;
  align-self: flex-start;

}

.flip-card {
  width: 242px;
  height: 312px;
  perspective: 1000px;
  overflow-y: hidden;
  cursor: pointer;
  border-radius: 10px;
border: 2px solid #222222;
background: #222222;

}
.flip-card-growth {
  width: 216px;
  height: 300px;
  perspective: 1000px;
  overflow-y: hidden;
  cursor: pointer;
  border-radius: 10px;
border: 2px solid #2C2C2C;
background: #131313;

}

.card-description-title {
  position: absolute;
  bottom: 0;
}


.flip-card:hover,
.flip-card-growth:hover {
  border-radius: 10px;
  border: 2px solid var(--Iris-Purple, #7855FA);
  background: #222222;
  box-shadow: 0px 4px 10px 0px #220F6A;

}


.flip-card-inner {
  position: relative;
  width: 100%;
  height: 100%;

}







.flip-card-front {
  position: absolute;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  width: 100%;
  height: 100%;



}
.flip-card-front:focus-visible {
  outline: unset !important;

}


.flip-card-front:focus-visible {
  border: unset !important;
  outline: unset !important;
}
.flip-card:focus-within {
   outline: 2px solid rgba(120, 85, 250, 0.75) !important; /* Define the outline style here */
}
.flip-card-back:focus-visible {
  border: unset !important;
  outline: unset !important;
}

.flip-card-back {
  position: absolute;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  width: 100%;
  height: 100%;


}

.flip-card-front {
  background: #222222;


}





.faqAccordion > .accordion-header > .accordion-toggle {
  width: 100% !important;
  justify-content: space-between !important;
}

.mobileHiringCarousel {
   padding: 10px 6px !important;
}

.openingFilter {
  background: #F0F0F0;
  border: 1px solid #F0F0F0;
  color: #8E8E8E !important;
  font-family: "Montserrat Medium" !important;
font-size: 12px !important;
font-style: normal;
font-weight: 500 !important;
line-height: 26px !important;
border-radius: 10px;
padding: 0px 10px !important;
white-space: nowrap !important;

}
.focusable-filter {
  white-space: nowrap !important;
}
.focusable-filter:focus-visible {
  outline: 2px solid rgba(120, 85, 250, 0.75) !important; /* Adjust color as needed */
  border-radius: 10px !important;
}

.openingFilter.activeClass {
  border-color: #7855FA;
  color: #7855FA !important;
}

.openingFilter:hover{
  cursor: pointer;

  color: #7855FA !important;
}

.faqQuestionsText {
  color: var(--text-primary-paragraph, #22272E);
font-family: 'Montserrat Medium' !important;
font-size: 12px !important;
font-style: normal;
font-weight: 500 !important;
line-height: 20px !important;
}

.hideCard {
  animation: cardAnimation 0.5s;
  animation-fill-mode: forwards;
}
.showCard {
  animation: cardAnimation1 0.5s;
}
.showDesc {
  animation: textAnimation 0.5s;
}
.hideDesc {
  animation: textAnimation1 0.5s;
}

.hideAnimation {
  animation: hideAnimation 0.5s;
}
.changeColor {
  color: #FFFFFF !important;
}

@keyframes cardAnimation {
  from { transform: translateY(0%); }
  to { transform: translateY(-72%);}
}


@keyframes hideAnimation {
  from { opacity: 1;}
  to { opacity: 0;}
}
@keyframes cardAnimation1 {
  from { transform: translateY(-70%);}
  to { transform: translateY(0%);}
}

@keyframes textAnimation {
  from { transform: translateY(100%); opacity: 0;}
  to { transform: translateY(0%); opacity: 1}
}
@keyframes textAnimation1 {
  from { transform: translateY(0%); opacity: 1;}
  to { transform: translateY(100%); opacity: 0;}
}

.careerLadder {
  overflow-x: scroll;
  scroll-behavior: smooth;
}
.careerLadder::-webkit-scrollbar {
    width: 8px !important;
    height: 8px !important;
    min-height: 1px !important;
}

.careerLadder::-webkit-scrollbar-track-piece  {
   background-color: #555555 !important;
    border-radius: 10px !important;
    max-height: 4px !important;
    height: 2px !important;
}

.careerLadder::-webkit-scrollbar-thumb {
    border-radius: 10px !important;
    background-color: #7855FA !important;
    width: 2px !important;

}
.careerLadder {
  scrollbar-width: thin; /* Options: auto, thin, none */
  scrollbar-color: #7855FA #555555; /* thumb color track color */
}



@media only screen and (min-width: 768px) {



#formLink:focus-visible:after {
  border: 2px solid rgba(120, 85, 250, 0.75) !important; /* Define the outline style here */
  border-radius: 10px !important;
}
#formLink > div > span {
  text-decoration: none !important;
}
.jobOverflow {
  overflow-y: scroll;
}

#filterDescription {
  white-space: nowrap !important;
}


  @keyframes cardAnimation {
    from { transform: translateY(0%); }
    to { transform: translateY(-72%);}
  }

  .internshipContainer {
     margin-left: -150px;
     margin-right: -150px;
  }

  .hireArrow {
    opacity: 0;
    transition: opacity .5s;
  }

  .flip-card:hover .hireArrow {
    opacity: 1 !important;
  }

  #openingsSection > div > div:last-child {

    flex-basis: 114% !important;

  }
.careersIllustration {
  margin-left: -148px !important;
  height: 400px;
  margin-bottom: 20px !important;
}

.jobTable {
  overflow-y: scroll;
}
  .jobTable ::-webkit-scrollbar-thumb {
    background: red;
  }

  .openingsFooter{

background: #FBFBFB;
border: 1px solid #EFE9DE;
border-radius: 4px;
padding: 16px 12px !important;
margin-top: 30px !important;
  }


  .faqQuestionsText {
    color: var(--text-primary-paragraph, #22272E);
font-family: 'Montserrat Regular' !important;
font-size: 15px !important;
font-style: normal;
font-weight: 600 !important;
line-height: 26px !important;
  }

  #openingsSection{
    padding: 210px 212px 0px !important;
  }
  #hireSection {
    padding: 140px 212px !important;
  }
  #openingsSection > div > div {
    padding-bottom: 100px;
  }
  #careerGrowthSection {

    background-color: #F9F9F9 !important;
  }
  #faqSection, #careerGrowthSection {
    padding: 140px 212px !important;
  }


.faqAccordion:hover{
  cursor: pointer;
}
.hireTile{

  transition: transform 1s;
  transform-style: preserve-3d;

  transform-origin: center center;
  cursor: pointer;
  position: relative;
}


.card__face {
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}
.scene {
  width: 100%;
  height: 100%;
  margin: 40px 0;
  perspective: 600px;
}

.card {
  width: 100%;
  height: 100%;
  transition: transform 1s;
  transform-style: preserve-3d;
  cursor: pointer;
  position: relative;
}

.card.is-flipped {
  transform: rotateY(180deg);
  transform-origin: center center;
}

.card__face {
  position: absolute;
  width: 100%;
  height: 100%;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
}



.card__face--back {

  transform: rotateY(180deg);
  transform-origin: center center;
}




.careerLadder {
  justify-content: flex-start !important;


}


.careerLadderHeading {
  background: linear-gradient(280.46deg, rgba(48, 177, 250, 0.67) -7.03%, rgba(235, 98, 22, 0.64) 120.08%);
  border-radius: 4px 4px 0px 0px;
  padding: 29px !important;
}

.articleCard {
  box-shadow: 0px 4px 16px rgba(186, 186, 186, 0.25);
border-radius: 3px;
padding: 20px !important;
background: #FFFFFF !important;
}


}


@media only screen and (min-width: 1023px) and (max-width: 1350px) {

   #hireSection, #careerGrowthSection, #faqSection{
    padding: 120px 48px !important;
  }
  .internshipContainer {
     margin-left: -48px;
     margin-right: -48px;
     padding: 50px 150px;
  }
  #openingsSection {
    padding: 120px 48px 0px !important;
  }
  #openingsSection > div > div:last-child {
    padding-left: 0px !important;
  }
  .hireTileTitle{
    white-space: normal !important;
  }
}
@media only screen and (min-width: 1441px) and (max-width: 1513px) {
  @keyframes cardAnimation {
    from { transform: translateY(0%); }
    to { transform: translateY(-72%);}
  }
}
@media only screen and (min-width: 1513px) and (max-width: 1792px) {
  #faqSection {
    padding: 200px 212px !important;
  }
}
@media only screen and (min-width: 1920px) {
  #openingsSection > section > div > div {
    padding-bottom : 318px !important;
  }
}
@media only screen and (min-width: 2000px) {

  @keyframes cardAnimation {
    from { transform: translateY(0%); }
    to { transform: translateY(-72%);}
  }
 .abc {
   width: 600px;
   margin: auto;
 }
 #openingsSection > section > div > div  {

   padding: 200px 140px 318px !important;
 }
 #openingsSection > section > div > div:last-child {
   width: 650px;
   margin-right: auto;
   flex-basis: 50% !important;

 }
  #faqSection {
    padding: 300px 132px 200px !important;
  }
  #hireSection {
    padding: 278px 132px !important;
  }
  #careerGrowthSection {
    padding: 177px 132px 132px !important;
  }

}

@media only screen and (min-width: 1350px) {
  .careerLadder {
    overflow-x: hidden;
  }

  .scrolling {
    overflow-x: scroll;
  }
  .scrolling::-webkit-scrollbar {
      width: 8px;
      height: 8px !important;
      min-height: 1px !important;
  }

  .scrolling::-webkit-scrollbar-track-piece  {
     background-color: #555555;
      border-radius: 10px;
      max-height: 4px !important;
      height: 2px !important;
  }

  .scrolling::-webkit-scrollbar-thumb {
      border-radius: 10px;
      background-color: #7855FA;
      width: 2px !important;

  }
}

@media only screen and (min-width: 3800px) {
  #openingsSection > section > div > div {
    padding: 200px 550px 318px !important;
  }
}
